import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CitiesLink from 'Components/CitiesLink'
import { BLUESYSTEMS_URL } from 'Components/constants'
import DataCard from 'Components/DataCard'
import Footer from 'Components/Footer'

function HomePage() {
  const { t } = useTranslation()

  return (
    <Stack direction="column" alignItems="center" gap={4}>
      <Stack
        direction="column"
        alignItems="center"
        width={1}
        pt={4}
        sx={(theme) => ({
          bgcolor: theme.palette.primary.main,
        })}
      >
        <Stack width={200} alignSelf="flex-start" mb={8} sx={{ px: { xs: 4, sm: 8 } }}>
          <a href={BLUESYSTEMS_URL}>
            <img src="/logo_bluesystems.svg" alt="bluesystems logo" />
          </a>
        </Stack>
        <Typography
          textAlign="center"
          variant="h1"
          px={4}
          mb={2}
          sx={(theme) => ({
            color: theme.palette.background.default,
          })}
        >
          {t('home_page.title')}
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          gap={4}
          p={2}
          px={4}
          width="60%"
        >
          <DataCard primaryText="65 M" secondaryText={t('home_page.cards.trips')} />
          <DataCard primaryText="64 K" secondaryText={t('home_page.cards.connected_vehicles')} />
          <DataCard primaryText={`6 ${t('home_page.cards.years')}`} secondaryText={t('home_page.cards.experience')} />
        </Stack>
        <Stack width={1} sx={{ height: { xs: 50, sm: 100, md: 200 } }}>
          <svg viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon fill="white" points="0,0 100,100 0,100" />
          </svg>
        </Stack>
      </Stack>
      <CitiesLink />
      <Footer />
    </Stack>
  )
}

export default HomePage
