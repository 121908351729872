import { Stack, Typography, CircularProgress } from '@mui/material'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import CityCard from 'Components/CityCard'

import { fetchCards } from './FetchData'

function CitiesLink() {
  const { t } = useTranslation()

  const cardsResults = useQuery({
    queryKey: 'fetchCards',
    queryFn: () => fetchCards(),
    keepPreviousData: true,
  })

  const maxWidth = useMemo(
    () => Math.max(...(cardsResults.data ?? []).map((card) => card.name.length), 14),
    [cardsResults.data]
  )

  if (cardsResults.isLoading) {
    return <CircularProgress />
  }

  if (!cardsResults.data) {
    return null
  }

  return (
    <Stack direction="column" alignItems="center" gap={4} px={4} width={'80%'} mb={8}>
      <Stack direction="column" alignItems="center">
        <Typography textAlign="center" variant="h2" sx={(theme) => ({ color: theme.palette.primary.main })}>
          {t('home_page.sub_title.title')}
        </Typography>
        <Typography textAlign="center" variant="h5" sx={(theme) => ({ color: theme.palette.primary.main })}>
          {t('home_page.sub_title.helper')}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        flexWrap={'wrap'}
        rowGap={2}
        columnGap={3}
        width={'100%'}
      >
        {cardsResults.data
          .sort((cardA, cardB) => -(Number(cardA.enabled) - Number(cardB.enabled)))
          .map((card) => (
            <CityCard
              key={card.slug}
              cityName={card.name}
              imagePath={card.cardPath}
              url={card.slug}
              disabled={!card.enabled}
              width={maxWidth}
            />
          ))}
      </Stack>
    </Stack>
  )
}

export default CitiesLink
