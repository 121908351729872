import { Stack } from '@mui/material'
import React from 'react'

import { IMetaData } from 'Components/FetchData'

import { BlueSystemsLogo } from './BlueSystemsLogo'
import { HeaderBreadcrumbs } from './HeaderBreadcrumbs'

interface IHeaderProps {
  metaData: IMetaData
}

export default function Header(props: IHeaderProps) {
  return (
    <Stack
      direction={'column'}
      alignItems={'flex-start'}
      width={1}
      pt={4}
      sx={(theme) => ({
        bgcolor: theme.palette.primary.main,
      })}
    >
      <BlueSystemsLogo />
      <HeaderBreadcrumbs
        logo={{
          path: `/${props.metaData.logoPath}`,
          name: props.metaData.name.replace(/[^a-zA-Z0-9 ]/g, ''),
        }}
      />
    </Stack>
  )
}
