import { Stack, CircularProgress } from '@mui/material'
import React from 'react'
import { useQueries } from 'react-query'
import { Navigate } from 'react-router-dom'

import { fetchCityMetaData } from 'Components/FetchData'
import Footer from 'Components/Footer'
import { useCityName } from 'hooks/useCityName'

import Header from './Header'

export default function CityPage() {
  const cityName = useCityName()
  const [metaDataResults] = useQueries([
    { queryKey: ['fetchMetaData', cityName], queryFn: () => fetchCityMetaData(cityName) },
  ])

  const error = metaDataResults.error

  if (metaDataResults.isLoading) {
    return <CircularProgress sx={{ position: 'absolute', inset: 0, margin: 'auto' }} />
  }

  if (error) {
    return <Navigate to="/" />
  }

  const metaData = metaDataResults.data!

  return (
    <Stack direction="column" alignItems="center" gap={4}>
      <Header metaData={metaData} />
      <Stack direction="column" alignItems="center" gap={8} width="100%">
        <iframe
          title={metaDataResults.data?.name}
          style={{ display: 'flex', width: '95vw', height: '100vh' }}
          src={`https://app.powerbi.com/view?r=${metaData.embedId}`}
        />
      </Stack>
      <Footer />
    </Stack>
  )
}
