export interface IMetaData {
  embedId: string
  logoPath: string
  name: string
}

export async function fetchCityMetaData(cityName: string): Promise<IMetaData> {
  const result = await fetch(`/datas/${cityName}.json`)
  if (!result.ok) {
    throw new Error(`Failed to fetch meta data for ${cityName}`)
  }
  return await result.json()
}

interface ICard {
  cardPath: string
  enabled: boolean
  name: string
  slug: string
}

export const fetchCards = async function (): Promise<ICard[]> {
  const result = await fetch('/datas/cards.json')
  if (!result.ok) {
    throw new Error(`Failed to fetch cards`)
  }
  return await result.json()
}
